import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import styled from "styled-components";
import siteConfig from "../../data/SiteConfig";

import Page from "../components/Layout/Page";
import { FixedWidthContainer } from "../components/Layout/ContentContainers";

import { forceHttps, useStaticDomain } from "../utils/strings";

const Entities = require("html-entities").AllHtmlEntities;

const entities = new Entities();

class WpPageTemplate extends React.Component {
  getMetaUrl() {
    const { pathContext } = this.props;
    let baseUrl = siteConfig.siteUrl;

    if (pathContext.slug) {
      baseUrl += `/${pathContext.slug}`;
    }
    return baseUrl;
  }

  getMetaTitle() {
    const { pathContext } = this.props;
    let pageTitle = "";

    if (pathContext.title) {
      pageTitle = `${pathContext.title} | ${siteConfig.siteTitle}`;
    }

    return  entities.decode(pageTitle);
  }

  render() {
    const { pathContext } = this.props;
    const pageNode = {
      title: entities.decode(pathContext.title),
      content: entities.decode(pathContext.content),
      id: pathContext.id,
      slug: pathContext.slug
    };

    return (
      <Page title={pageNode.title}>
        <Helmet>
          <meta property="og:url" content={this.getMetaUrl()} />
          <meta property="og:title" content={this.getMetaTitle()} />
        </Helmet>
        <FixedWidthContainer>
          <WPPageContentContainer>
            <div
              dangerouslySetInnerHTML={{
                __html: useStaticDomain(forceHttps(pageNode.content))
              }}
            />
          </WPPageContentContainer>
        </FixedWidthContainer>
      </Page>
    );
  }
}

const WPPageContentContainer = styled.div`
  img {
    height: auto;
    max-width: 100%;
  }
`;

export default WpPageTemplate;
